import service from "@/api/service";
import { GetResponse } from "@/api/ticket-lottery-shop/response";
import { SearchRequest } from "@/api/ticket-lottery-shop/request";
import { SearchResponse } from "@/api/ticket-lottery-shop/response";

/**
 * チケット抽選管理店舗一覧検索APIをコールします。
 *
 * @param searchRequest チケット抽選管理店舗一覧検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post(
    "/search-ticket-lottery-shop",
    searchRequest
  );
  return response.data as SearchResponse;
}

/**
 * チケット抽選管理店舗名取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-ticket-lottery-shop");
  return response.data as GetResponse;
}
